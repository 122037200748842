
export default {
  data () {
    return {
      items: [
        {
          id: 1,
          to: this.localePath({ name: 'user' }),
          title: this.$t('pages.user.index.title'),
          icon: ['fas', 'id-card-clip']
        },
        // {
        //   id: 2,
        //   to: this.localePath({ name: 'user-preferences' }),
        //   title: this.$t('pages.user.preferences.title'),
        //   icon: ['fas', 'square-check']
        // },
        {
          id: 3,
          to: this.localePath({ name: 'user-orders' }),
          title: this.$t('pages.user.orders.title'),
          icon: ['fas', 'money-check-dollar']
        },
        {
          id: 4,
          to: this.localePath({ name: 'user-wishlist' }),
          title: this.$t('pages.user.wishlists.title'),
          icon: ['fas', 'heart']
        },
        {
          id: 5,
          to: this.localePath({ name: 'user-addresses' }),
          title: this.$t('pages.user.addresses.title'),
          icon: ['fas', 'address-card']
        },
        {
          id: 6,
          to: this.localePath({ name: 'user-password' }),
          title: this.$t('pages.user.password.title'),
          icon: ['fas', 'key']
        }
      ]
    }
  },

  methods: {
    logout () {
      this.$auth.logout().then(() => this.$router.back())
    }
  }
}
